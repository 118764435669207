<template>
  <div class="">
    <h2>Referral Portal</h2>

    <div class="card-body">
      <div class="row">
        <div class="col-md-4 chartContainer">
          <div class="DashboardItemTitle card-header">Clients</div>
          <div class="card DashboardItem"><dummyData /></div>
        </div>
        <div class="col-md-4 chartContainer">
          <div class="DashboardItemTitle card-header">
            Providers currently working with
          </div>
          <div class="card DashboardItem"><dummyData /></div>
        </div>

        <div class="col-md-4 chartContainer">
          <div class="DashboardItemTitle card-header">
            Upcoming appointments
          </div>
          <div class="card DashboardItem"><dummyData /></div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 chartContainer">
          <div class="DashboardItemTitle card-header">Send Task / Message</div>
          <div
            class="card DashboardItem"
            style="padding-bottom: 30px; max-height: none !important"
          >
            <task-creae />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dummyData from "./dummyList.vue";
import taskCreae from "../tasks/Create.vue";
export default {
  name: "Index",
  components: {
    dummyData,
    taskCreae,
  },
  computed: {
    ...mapState({
      patients: (s) => s.patients.data, //TO get , my patients
      //clients: s=> s.clients.data, //TODO , add them when ready
      //alerts: s=> s.alerts.data,//TODO , add them when ready
    }),
  },
  data() {
    return {
      options: {
        chart: {
          id: "vuechart-example",
        },
        horizontal: "true",
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        },
      },

      optionsH: {
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
      },

      series: [
        {
          name: "series-1",
          data: [30, 40, 45, 50, 49, 60, 70, 91],
        },
      ],

      optionsPie: {
        chart: {
          id: "vuechart-example2",
        },
      },
      seriesPie: [44, 55, 41, 17, 15],
    };
  },
};
</script>
