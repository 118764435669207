var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      !_vm.propProviderId &&
      _vm.user &&
      (_vm.user.isAdmin || _vm.user.isManagingAdmin) &&
      _vm.$can({ key: 'providers', expectedPermission: 'edit' })
    )?_c('button',{staticClass:"btn btn-outline-blue mb-3",on:{"click":_vm.addNewClass}},[_c('icon',{attrs:{"type":"plus"}}),_vm._v(" Add Class ")],1):_vm._e(),(_vm.isLoading)?_c('alert'):_vm._e(),(!_vm.isLoading && _vm.provider != null && _vm.classifications.length == 0)?_c('alert',{staticClass:"my-4",attrs:{"hideLoader":true}},[_vm._v(" No Classes Attached")]):_vm._e(),(
      _vm.provider != null && _vm.provider.user != null && _vm.classifications.length > 0
    )?_c('div',{staticClass:"card table-container light-shadow",class:!_vm.propProviderId && 'shadow-card'},[_c('table',{staticClass:"table table-striped mb-0"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Subject")]),(
              !_vm.propProviderId &&
              _vm.user &&
              (_vm.user.isAdmin || _vm.user.isManagingAdmin || _vm.user.isProvider) &&
              _vm.$can({ key: 'providers', expectedPermission: 'edit' })
            )?_c('th',[_vm._v(" detach ")]):_vm._e(),_c('th',{staticClass:"desktop-only"})])]),_c('tbody',_vm._l((_vm.classifications),function(classification){return _c('tr',{key:classification.id},[_c('td',[_vm._v(_vm._s(classification.subject))]),(
              !_vm.propProviderId &&
              _vm.$can({ key: 'providers', expectedPermission: 'edit' })
            )?_c('td',[_c('span',{staticClass:"ml-2 text-danger pointer-cursor"},[_c('i',{staticClass:"fas fa-unlink",on:{"click":function($event){return _vm.detachSelectedClass($event, classification.id)}}})])]):_vm._e(),_c('td',{staticClass:"desktop-only"})])}),0)])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }