<template>
  <div>
    <button
      class="btn btn-outline-blue mb-3"
      @click="addNewClass"
      v-if="
        !propProviderId &&
        user &&
        (user.isAdmin || user.isManagingAdmin) &&
        $can({ key: 'providers', expectedPermission: 'edit' })
      "
    >
      <icon type="plus" /> Add Class
    </button>

    <alert v-if="isLoading" />
    <alert
      :hideLoader="true"
      class="my-4"
      v-if="!isLoading && provider != null && classifications.length == 0"
    >
      No Classes Attached</alert
    >
    <div
      class="card table-container light-shadow"
      :class="!propProviderId && 'shadow-card'"
      v-if="
        provider != null && provider.user != null && classifications.length > 0
      "
    >
      <table class="table table-striped mb-0">
        <thead>
          <tr>
            <th>Subject</th>
            <th
              v-if="
                !propProviderId &&
                user &&
                (user.isAdmin || user.isManagingAdmin || user.isProvider) &&
                $can({ key: 'providers', expectedPermission: 'edit' })
              "
            >
              detach
            </th>
            <th class="desktop-only"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="classification in classifications"
            :key="classification.id"
          >
            <td>{{ classification.subject }}</td>

            <td
              v-if="
                !propProviderId &&
                $can({ key: 'providers', expectedPermission: 'edit' })
              "
            >
              <span class="ml-2 text-danger pointer-cursor"
                ><i
                  class="fas fa-unlink"
                  @click="detachSelectedClass($event, classification.id)"
                ></i
              ></span>
            </td>
            <td class="desktop-only"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "provider-classifications",
  props: ["propProviderId"],
  data() {
    return {
      providerId: this.$route.params.id,
      isLoading: false,
    };
  },
  mounted() {
    if (this.propProviderId && !this.provider) {
      this.$store.dispatch("providers/get", { id: this.propProviderId });
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      provider: (state) => state.providers.provider,
      classifications: (state) => state.providers.provider.user.classifications,
    }),
  },
  methods: {
    ...mapActions({
      detach: "classifications/detachClassFromUser",
      getProviderData: "providers/get",
    }),
    addNewClass: function () {
      this.$router.push({
        name: "providers.classes.create",
        params: { id: this.providerId },
        query: { src: "classes" },
      });
    },

    detachSelectedClass: function ($event, id) {
      $event.preventDefault();
      const vm = this;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, detach it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            vm.detach({ id: id, user: vm.provider.user_id }).then((detach) => {
              if (detach) {
                this.getProviderData({
                  id: vm.providerId || vm.propProviderId,
                });
              } else {
                swalWithBootstrapButtons.fire(
                  "Error!",
                  "Something went wrong...",
                  "error"
                );
              }
            });
          }
        });
    },
  },
};
</script>
